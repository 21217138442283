<template>
	<div>
		<b-card title="Jenis Pejekerjaan">
			<b-row class="mb-2 align-items-center">
				<b-col cols="12" md="6">
					<div class="form-group form-row mb-0">
						<label class="col-4 col-form-label">Pilih Group :</label>
						<div class="col">
							<v-select
								v-model="filter.group_uuid"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								label="name"
								placeholder="-- Pilih Group --"
								:options="group"
								:reduce="group => group.uuid"
								@keyup.stop.native="searchGroup"
							/>
						</div>
					</div>	
				</b-col>
				<b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex" style="gap:10px;">
							<label>Search:</label>
							<input 
								type="search"
								class="form-control"
								v-model="filter.keyword" 
								placeholder="Jenis Pekerjaan" 
								aria-label="Search"
							>
						</div>
					</form>
				</b-col>
			</b-row>
			<div class="table-responsive">
				<table class="table b-table text-nowrap">
					<thead>
						<tr>
							<th rowspan="2" scope="col" class="align-middle">KLU</th>
							<th rowspan="2" scope="col" class="align-middle">Nama</th>
							<th rowspan="2" scope="col" class="align-middle">Masking</th>
							<th colspan="4" scope="col" class="text-center">Norma</th>
							<th rowspan="2" scope="col" class="align-middle">Actions</th>
						</tr>
						<tr>
							<th scope="col">Tahun</th>
							<th scope="col">Provinsi (%)</th>
							<th scope="col">Ibukota (%)</th>
							<th scope="col">Daerah Lainnya (%)</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="isLoading">
							<td
								colspan="12"
								class="text-center"
							>
							<b-spinner
								class="mb-1"
								variant="primary"
							/>
							<br>
								Loading...
							</td>
						</tr>
						<tr v-else v-for="item, key in workingTypes" :key="key">
							<td>{{ item.klu_code }}</td>
							<td>{{ item.name }}</td>
							<td>
								<div class="d-flex align-items-center">
									<b-dropdown
										variant="flat-secondary"
										size="sm"
									>
										<template #button-content>
											{{ typeof item.maskings[0] != 'undefined' ? item.maskings[0].name : '-' }}
										</template>
										<template v-if="item.maskings != ''">
										<b-dropdown-item v-for="items, index in item.maskings" :key="index">
											{{ items.name || '-' }}
										</b-dropdown-item>
										</template>
										<template v-else>
											<b-dropdown-item>
												No Data
											</b-dropdown-item>
										</template>
									</b-dropdown>
								</div>
							</td>
							<td>
								<div class="d-flex align-items-center">
									<b-dropdown
										variant="flat-secondary"
										size="sm"
									>
										<template #button-content>
											{{ typeof item.norma[0] != 'undefined' ? item.norma[0].year : '-' }} 
										</template>
										<template v-if="item.norma != ''">
											<b-dropdown-item v-for="items, index in item.norma" :key="index">
												{{ items.year }}
											</b-dropdown-item>
										</template>
										<template v-else>
											<b-dropdown-item>
												No Data
											</b-dropdown-item>
										</template>
									</b-dropdown>
								</div>
							</td>
							<td>
								<div class="d-flex align-items-center"> 
									<b-dropdown
										variant="flat-secondary"
										size="sm"
									>
										<template #button-content>
											{{ typeof item.norma[0] != 'undefined' ? item.norma[0].province_capital : '-' }}
										</template>
										<template v-if="item.norma != ''">
											<b-dropdown-item v-for="items, index in item.norma" :key="index">
												{{ items.province_capital }}
											</b-dropdown-item>
										</template>
										<template v-else>
											<b-dropdown-item>
												No Data
											</b-dropdown-item>
										</template>
									</b-dropdown>
								</div>
							</td>
							<td>
								<div class="d-flex align-items-center">
									<b-dropdown
										variant="flat-secondary"
										size="sm"
									>
										<template #button-content>
											{{ typeof item.norma[0] != 'undefined' ? item.norma[0].province_capital_other : '-' }}
										</template>
										<template v-if="item.norma != ''">
											<b-dropdown-item v-for="items, index in item.norma" :key="index">
												{{ items.province_capital_other }}
											</b-dropdown-item>
										</template>
										<template v-else>
											<b-dropdown-item>
												No Data
											</b-dropdown-item>
										</template>
									</b-dropdown>
								</div>
							</td>
							<td>
								<div class="d-flex align-items-center">
									<b-dropdown
										variant="flat-secondary"
										size="sm"
									>
										<template #button-content>
											{{ typeof item.norma[0] != 'undefined' ? item.norma[0].other_region : '-' }}
										</template>
										<template v-if="item.norma != ''">
											<b-dropdown-item v-for="items, index in item.norma" :key="index">
												{{ items.other_region }}
											</b-dropdown-item>
										</template>
										<template v-else>
											<b-dropdown-item>
												No Data
											</b-dropdown-item>
										</template>
									</b-dropdown>
								</div>
							</td>
							<td>
								<b-badge 
									variant="warning"
									class="d-flex align-items-center badge-glow cursor-pointer"
									style="gap:5px;"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									v-b-tooltip.hover.bottom="'Tambah Masking'"
									@click="createWorkingType(item.uuid)"
								>
									<feather-icon
										:id="`invoice-row-${item.uuid}-send-icon`"
										icon="EditIcon"
										size="12"
									/>
									Masking
								</b-badge>
							</td>
						</tr>
					</tbody>
				</table>
				<div
					v-if="result.total > 0"
					class="m-1">
					<div class="row">
						<div class="col mb-1">
							<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
						</div>
						<div class="col">
							<pagination
								:data="result"
								:limit="4"
								align="right"
								@pagination-change-page="getData"
							/>
						</div>
					</div>
				</div>
			</div>
            <b-modal
				id="form-list-pekerjaan" 
				centered
				title="Tambah Masking"
				size="lg"
				no-close-on-backdrop
				hide-footer 
			>
				<ValidationObserver>
					<div class="form">
						<div class="form-group">
							<label>Nama Pekerjaan</label>
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Nama Group" 
									v-model="formPayload.name"
									disabled
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<h3>Masking</h3>
						<b-button
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary mb-2"
							@click="addMaskings"
						>
							+ Add New
						</b-button>
						<div 
							class="form-group" 
							v-for="(item , index) in inputMaskings" 
							:key="index"
						>
							<label>Nama Masking</label>
							<validation-provider
								name="Nama Masking"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<div class="d-flex w-100" style="gap: 5px;">
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Nama Masking"
										v-model="item.name"
									>
									<b-button
										variant="danger"
										class="btn waves-effect waves-float waves-light btn-danger d-flex"
										style="gap: 4px;"
										@click="removeMaskings(index)"
									>
										Delete
										<feather-icon
											icon="TrashIcon"
											class="cursor-pointer"
										/>
									</b-button>
								</div>
								<small
									v-for="(validation, index) in validations['maskings.' + index]"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</div>
					<div v-if="isLoading">
						<br>
						<b-spinner
							class="mb-2"
							variant="primary"
						/><br>
					</div>
					<b-button variant="primary" @click="updateItem()" v-bind:disabled="isLoading">
						Simpan
					</b-button>
				</ValidationObserver>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	VBTooltip,
	BPagination,
	VBModal,
	BButton,
	BSpinner,
	BRow,
	BBadge,
	BCol,
	BDropdown,
	BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { successNotification, errorNotification } from '@/auth/utils'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import _ from 'lodash'

configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BRow,
		BBadge,
		BCol,
		vSelect,
		BDropdown,
		BDropdownItem,
		ValidationProvider, 
		ValidationObserver,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
    'b-modal': VBModal,
	'b-tooltip': VBTooltip,
		Ripple,
	},
	setup() {
		return {
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: [
				'klu_code', 
				'name', 
				'actions'
			],
			activity: [],
			filter: {
				keyword: '',
				group_uuid: ''
			},
			inputMaskings: [
				{
					name: '',
				}
			],
			group: [],
			profession: [],
            formPayload: Object,
            businessTypeSelected:[],
            workingTypes: [],
            businessTypes: [],
            editId: null,
			validations: ''
		}
	},
	computed: {
		rows() {
			return this.workingTypes.length
		}
	},

	methods: {
		title() {
			return `Activity Log`
		},
		modalId(id) {
			return 'modal_' + id;
		},
		addMaskings() {
        	this.inputMaskings.push({ 
				name: '',
			});
		},
		removeMaskings(index) {
			this.inputMaskings.splice(index, 1);
		},

        getDataByUuid(uuid) {
            this.$http.get('/admin/business-types/' + uuid)
            .then(response => {
                let businessTypeGroup = response.data.data
                this.formPayload = {
                    name: businessTypeGroup.name,
                    masking: businessTypeGroup.maskings,
                }
				this.inputMaskings = this.formPayload.masking
			})
        },

		preparePayload() {
			const form = new FormData()
			this.formPayload.maskings = this.inputMaskings.map(business => business.name)
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {	
					if (key != 'name' && key != 'masking' && key != 'maskings') {
						form.append(key, this.formPayload[key])
					}
					if (key == 'maskings') {
						for (let index = 0; index < this.formPayload.maskings.length; index++) {
							const element = this.formPayload.maskings[index];
							form.append('maskings[]', element)
						}
					}
				}
			}
			return form;
		},

		updateItem() {
			var form = this.preparePayload();
			this.isLoading = true
			this.$http.post(`admin/business-types/${this.editId}/masking`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-list-pekerjaan')
				this.getData()
				successNotification(this, 'Success', 'List Jenis pekerjaan successfully updated!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations         
				}
				this.isLoading = false
			})
		},

		searchGroup: _.debounce(function (e) {
			const keyword = e.target.value
			this.loadGroupList(keyword)
		}, 300),

		loadGroupList(keyword) {
			this.$http.get('/admin/groups/business-type', {
				params: {
					'keyword': keyword != null ? keyword : null
				}
			}).then(response => {
				this.group = response.data.data.data
			})
		},

		getData(page) {
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('/admin/business-types', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				console.log(this.result, 'ini data');
				this.workingTypes = response.data.data.data
				console.log(this.workingTypes, 'ini data');
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
        cleanUpForm() {
			this.editId = null
			this.formPayload = {
				name: '',
				business_types:[],
			};
			this.businessTypeSelected = []
		},

        createWorkingType(uuid) {
            this.editId = uuid
            this.getDataByUuid(uuid)
			this.$bvModal.show('form-list-pekerjaan')
		},

        deleteBusinessTypeGroup(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Group ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/groups/business-type/' + uuid)
					successNotification(this, 'Success', 'Group berhasil dihapus!')
					this.getData()
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getData();
		this.loadGroupList();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
